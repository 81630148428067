var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.usreList.length)+") Njangi Groups")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.usreList,"item-key":"full_name","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getMoreInfo(item.id)}}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v(_vm._s(item.contact))])])]}},{key:"item.contributions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contributions)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_time)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }