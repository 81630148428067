const data = [
  {
    responsive_id: '',
    id: 95,
    full_name: 'Kumbo Strikers Association',
    contact: '237 xxx xxx xxx',
    contributions: '10,000 XAF/Monthly',
    members: '32',
    status: 'Pending',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2022 - 11:13 PM',
  },
  {
    responsive_id: '',
    id: 1,
    full_name: "Babanki Womens Empowerment",
    contact: '237 xxx xxx xxx',
    contributions: '50,000 XAF/Monthly',
    members: '15',
    status: 'Cancelled',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:03 AM',
  },
  {
    responsive_id: '',
    id: 7,
    full_name: "PSS Mankon Ex-Students Association",
    contact: '237 xxx xxx xxx',
    contributions: '500,000 XAF/Monthly',
    members: '21',
    status: 'Validated',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 10:13 AM',
  },
  {
    responsive_id: '',
    id: 3,
    full_name: 'Nso Diaspora Association',
    contact: '237 xxx xxx xxx',
    contributions: '35,000 XAF/Monthly',
    members: '7',
    status: 'Validated',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:00 AM',
  },
  {
    responsive_id: '',
    id: 4,
    full_name: 'New Vision Excellence',
    contact: '237 xxx xxx xxx',
    contributions: '17,000 XAF/Monthly',
    members: '12',
    status: 'Validated',
    validation: true,
    date_time: 'Friday, 5 Jan. 2021 - 09:13 PM',
  },
]

export default data
